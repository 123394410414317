.tableGrey {
  background-color: #f2f2f2;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  padding: .5rem .25rem
}

.tableWhite {
  background-color: #fff;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  padding: .5rem .25rem
}

.tableMonths {
    min-width: 7rem;
}
.leftBorder {
    border-left: 1px solid lightgray;
}
.botBorder {
    border-bottom: 1px solid lightgray;
}

.ant-select-selection-overflow {
    overflow-y: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 10rem;
}