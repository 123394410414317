@import '~antd/lib/style/themes/default.less';

.login-wrapper {
    background: #ffffff;
    border-radius: 2px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: relative;
    min-width: 250px;
    top: 50%;
    left: 50%;
    margin-left: -125px;
    transform: translateY(-50%);
}

.login-layout {
    position: relative;
    width: 100%;
    min-width: 300px;
    height: 100vh;
    background: #f5f5f5;
    background-size: cover;
}
