.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.main-header {
    text-align: right;
    position: fixed;
    z-index: 10;
    width: calc(100% - 200px);
    border-bottom: 1px solid #f0f0f0;
}

main > section {
    height: 100%;
}

.user-panel {
    float: right;
    min-width: 200px;
    padding-right: 20px;
}

.app-logo {
    height: 64px;
    border-radius: 0px;
    width: 100%;
    padding: 10px 80px 10px 0px;
    background-color: white;
}

.home-icon {
    background: inherit !important;
    cursor: default;
}

.ant-layout-header {
    height: 64px;
    padding: 0 0px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
}

.site-layout-sidebar {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 1000px;
    position: fixed;
    z-index: 11;
    height: 100%;
}
